import "./index.scss";
import * as d3 from "d3";

// custom renderer : https://observablehq.com/@d3/force-directed-lattice

//  https://www.amcharts.com/demos/collapsible-force-directed-tree/

export const createCanvas = (data: any) => {
  var canvas = document.createElement("canvas");

  canvas.id = "canvas";
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(canvas);

  const root = d3.hierarchy(data);
  const baseLinks = root.links();
  const baseNodes = root.descendants();

  const imageMap: any = {
    player1: new Image(),
    player2: new Image(),
    player3: new Image(),
    player4: new Image(),
    // team: new Image(),
  };

  baseNodes.forEach((node: any, i) => {
    if (node.data.isTeamPreset) {
      // node.data.fx = 0;
      // node.data.fy = 0;
      // node.fx = 0;
      // node.fy = 0;
    }
    const img = imageMap[node.data.id];
    if (img) {
      img.src = node.data.img;
    }
    node.isExpanded = false;
  });
  let currentLinks = [...baseLinks];
  let currentNodes = [...baseNodes];
  let width = window.innerWidth;
  let height = window.innerHeight;

  const getLeafNodes = (nodeToBeTrimmed: any, result: any[] = []) => {
    const { children } = nodeToBeTrimmed;

    if (children) {
      result.push(nodeToBeTrimmed);
      children.map((childNode: any) => getLeafNodes(childNode, result));
    } else {
      result.push(nodeToBeTrimmed);
    }

    return result;
  };

  const simulation = d3
    //@ts-ignore
    .forceSimulation(currentNodes)
    .force(
      "link",
      d3
        //@ts-ignore
        .forceLink(currentLinks)
        //@ts-ignore
        .id((d) => d.id)
        .distance(170)
    )
    .force("charge", d3.forceManyBody().strength(-190));

  const addEventListeners = (simulation: any): any => {
    function dragstarted(event: any) {
      // if (event.subject.data.isTeamPreset) return;
      console.log("🚀 ~ event.subject", event.subject);
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event: any) {
      // if (event.subject.data.isTeamPreset) return;
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event: any) {
      // if (event.subject.data.isTeamPreset) return;
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  d3.select(canvas).call(
    addEventListeners(simulation).subject(({ x, y }: any) => {
      // remove the translate of our canvas drawing calcs!
      const found = simulation.find(x - width / 2, y - height / 2);
      return found;
    })
  );
  const hideChildren = (clickedNode: any) => {
    const leafNodes = getLeafNodes(clickedNode);
    leafNodes.shift();
    leafNodes.forEach((node) => console.log(node.data.id));
    const leafLinks = currentLinks.filter((link) =>
      leafNodes.includes(link.target)
    );
    // // Remove links
    leafLinks.forEach((linkToBeRemoved) => {
      currentLinks.splice(
        currentLinks.findIndex((link) => link === linkToBeRemoved),
        1
      );
    });

    // Remove nodes
    leafNodes.forEach((nodeToBeRemoved: any) => {
      const nodeIndex = currentNodes.findIndex(
        (node) => node.data.id === nodeToBeRemoved.data.id
      );
      if (nodeIndex > -1) {
        const removed = currentNodes.splice(nodeIndex, 1);
        removed.forEach((r: any) => (r.isExpanded = true));
      }
    });
  };
  const showChildren = (clickedNode: any) => {
    const directLeafNodes: any[] =
      baseNodes.filter((n) => n.data.id === clickedNode.data.id)[0].children ||
      [];

    //add the above nodes back into "current Nodes"
    directLeafNodes.forEach((nodeToBeAdded: any) => {
      currentNodes.push(nodeToBeAdded);
    });

    const leafLinks = baseLinks.filter(
      (link) => link.source.data.id === clickedNode.data.id
    );
    console.log("🚀 ~ leafLinks", leafLinks);
    // Add links
    leafLinks.forEach((l) => currentLinks.push(l));
  };
  canvas.addEventListener("click", (e) => {
    const x = e.pageX;
    const y = e.pageY;
    const clickedNode: any = simulation.find(
      x - width / 2,
      y - height / 2,
      440
    );

    if (!clickedNode) return;

    clickedNode.isExpanded = !clickedNode.isExpanded;
    // showChildren(clickedNode);
    if (clickedNode.isExpanded) {
      showChildren(clickedNode);
    } else {
      hideChildren(clickedNode);
    }
    currentNodes.forEach((node: any, i) => {
      if (node.data.isTeamPreset) {
        // node.fx = 0;
        // node.fy = 0;
      }
    });
    // @ts-ignore
    simulation.nodes(currentNodes);
    simulation.alpha(1);
  });

  window.addEventListener("keydown", () => {
    // @ts-ignore
    simulation.nodes(currentNodes);
    simulation.alpha(1);
  });

  simulation.on("tick", () => {
    const canvas: any = document.getElementById("canvas");
    if (!canvas) return;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, width, height);
    context.save();
    context.translate(width / 2, height / 2);
    context.beginPath();

    for (const d of currentLinks as any) {
      context.moveTo(d.source.x, d.source.y);
      context.lineTo(d.target.x, d.target.y);
    }
    context.strokeStyle = "white";
    context.stroke();
    context.beginPath();

    for (let index = 0; index < currentNodes.length; index++) {
      const d: any = currentNodes[index];
      const isTeamPresetNode = d.data.isTeamPreset;

      const isPlayerNode = d.data.isPlayer;
      const nodeSize = isTeamPresetNode ? 10 : isPlayerNode ? 7 : 5;
      const pixelSize = isTeamPresetNode ? "45" : isPlayerNode ? "30" : "15";
      const label = `${d.data.name} ${
        d.data.value !== undefined ? ` : ${d.data.value}` : ""
      }`;
      context.beginPath();
      context.font = `${pixelSize}px NeueRational`;
      context.fillStyle = "black";
      context.textAlign = "center";
      const fill = isTeamPresetNode
        ? "#FFFFFF"
        : isPlayerNode
        ? "#000000"
        : "#000000";
      context.fillStyle = fill;
      context.fillText(label.toUpperCase(), d.x, d.y + 10);
      if (imageMap[d.data.id]) {
        const w = 40;
        context.drawImage(
          imageMap[d.data.id],
          d.x - w / 2,
          d.y - (w + 10),
          w,
          w
        );
        console.log("🚀 ~ d.data.id", d.data.id);
      }
      // context.drawImage(imageMap.player1, d.x, d.y);
      // context.arc(d.x, d.y, nodeSize, 0, 2 * Math.PI);
      // context.fill();
      context.stroke();
    }

    context.restore();
  });

  const resizeCanvas = () => {
    const canvas: any = document.getElementById("canvas");
    if (!canvas) return;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    width = window.innerWidth;
    height = window.innerHeight;
  };
  window.addEventListener("resize", resizeCanvas, false);
  resizeCanvas();

  hideChildren(currentNodes[0]);
};
